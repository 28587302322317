@import '../../settings.scss';

.edit-media {
    margin-top: 20px;
    padding: 10px;
    min-width: 320px;

   &__row {
        width: 100%;
        max-width: 700px;

        h1, h2, h3 {
            margin: 15px 0;
        }

        .toggle-wrapper {
            max-width: 420px;
        }
    }

    &__divider {
        border-top: 1px solid #efefef;
        margin: 20px 0;
    }

    .media-retention__container {
        display: flex;
        justify-content: space-between;
        width: 310px;

        input {
            width: 100px;
        }

        .dropdown {
            width: 150px;

            &__label {
                background-color: var(--dropdown_background);
            }
        }

        .hint {
            display: flex;
            align-items: center;
            font-size: small;
        }
    }

    .time-unit__option {
        display: flex;
        border-bottom: var(--dropdown_background) solid 2px;
        height: 40px;

        span {
            display: flex;
            align-items: center;
        }

        :nth-child(2) {
            svg {
                fill: var(--primary);
            }
        }
    }

    .success-message {
        position: absolute;
        opacity: 0;
        bottom: 0;
        translate: 0 15px;
        transition: opacity 0.3s;
    
        span {
            color: $grey_dark;
        }
    
        &--isShown {
            opacity: 1;
        }
    }
}
