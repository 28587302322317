@import "../../settings.scss";

.phonebook {
    margin-top: 20px;
    padding: 10px;
    width: 100%;

    form {
        min-width: 320px;
        max-width: 700px;

        button {
            margin-top: 20px;
        }

        input {
            width: 300px;
        }

        & > div + div {
            margin-top: 20px;
        }

        border-bottom: 1px solid #efefef;
        padding-bottom: 40px;
    }

    form + h2 {
        margin-top: 40px;
    }

    &__row {
        width: 100%;
        max-width: 700px;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;

        & > textarea,
        & > input[type="text"],
        & > input[type="tel"] {
            margin-right: 20px;
        }

        & > span {
            padding: 5px;
            color: $grey_dark;
            max-width: 50%;
            line-height: 1rem;
            font-size: 12px;
        }
    }

    .hint {
        display: block;
        color: var(--font_color);
    }

    &__search {
        margin-top: 20px;
        display: flex;
        align-items: center;

        h3 {
            margin-right: 20px;
        }

        input {
            min-width: 300px;
        }
    }

    &__entries {
        margin-top: 30px;
    }

    &__entry {
        padding: 15px 10px;
        display: flex;
        align-items: flex-start;

        &:hover {
            background-color: $grey_light;
        }

        .phonebook__entry-value {
            padding: 10px 0;
            word-break: break-word;
            word-wrap: break-word;
            hyphens: auto;
        }
        .phonebook__entry-headers,
        .phonebook__entry-value,
        & > div {
            width: calc(20% - 20px);
            margin-right: 20px;
        }

        .phonebook__entry-buttons {
            width: 40%;

            button {
                border: none;
            }
        }

        input {
            width: 100%;
        }

        button {
            font-size: 0.9em;
            border-color: white;
            color: white;

            &:first-child {
                background: var(--edit_color);
            }
            &:last-child {
                background: var(--delete_color);
            }
            &.activate,
            &.phonebook__save {
                background: var(--activate_color);
            }
            &.deactivate {
                background: var(--deactivate_color);
            }

            &:hover {
                opacity: 0.8;
            }

            svg {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 4px;
                fill: white;
            }
        }

        button + button {
            margin-left: 10px;
        }
    }

    &__entry--header {
        font-weight: bold;

        span {
            cursor: pointer;
        }

        svg {
            display: inline-block;
            width: 24px;
            vertical-align: middle;
            fill: $grey;
        }

        .is-active {
            svg {
                fill: var(--primary);
            }
        }

        .sort-desc {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__entry + &__entry {
        border-top: 1px solid $grey_light;
    }

    &__success-message {
        opacity: 1;
        margin-top: 10px;
        margin-bottom: -10px;
        transition: opacity 0.3s;

        span {
            color: $grey_dark;
        }

        &--isHidden {
            opacity: 0;
        }
    }
}
