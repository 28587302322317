@import "../../settings.scss";

.center-disclaimer {
    margin-top: 20px;
    padding: 10px;
    width: 100%;

    input {
        width: 300px;
    }

    .toggleSwitch {
        max-width: 300px;
    }

    & > div {
        margin-bottom: 20px;
        padding-top: 20px;
    }

    & > div + div {
        padding-top: 25px;
        border-top: 1px solid #efefef;
    }

    &__area {
        margin-bottom: 40px;

        label {
            max-width: 700px;
        }

        .hint {
            display: block;
            color: $grey_dark;
            font-size: 12px;
            line-height: 1rem;
            max-width: 300px;
        }

        .confirmation-request-succeeded-message {
            position: absolute;
            opacity: 0;
            bottom: 0;
            translate: 0 25px;
            transition: opacity 0.3s;

            span {
                color: $grey_dark;
            }

            &--isShown {
                opacity: 1;
            }
        }

        .flex__group {
            height: 100%;
            display: flex;
            flex-direction: column;
            
            h3 {
                width: 100%;
                margin: 0 15px 15px 0;
            }

            button {
                width: 80px;
                position: relative;

                svg {
                    height: 20px;
                    margin: 0;
                    scale: 1
                }
            }
            
            button:disabled {
                svg {
                    fill: #fff;
                }  
            }
            
            .hint {
                translate: 0 28px;
            }
        }

    }

    &__row {
        width: 100%;
        max-width: 700px;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;

        & > textarea,
        & > input[type="text"],
        & > input[type="tel"] {
            margin-right: 20px;
        }

        & > span {
            padding: 5px;
            color: $grey_dark;
            max-width: 50%;
            line-height: 1rem;
            font-size: 12px;
        }
    }

    &__group {
        position: relative;
        .success-message {
            position: absolute;
            opacity: 0;
            bottom: 0;
            translate: 0 25px;
            transition: opacity 0.3s;

            span {
                color: $grey_dark;
            }

            &--isShown {
                opacity: 1;
            }
        }
    }

    .ml-20 {
        margin-left: 20px;
    }

    .justify-start {
        justify-content: flex-start;
    }

    .align-start {
        align-items: flex-start;
    }
}
