@import '../../settings.scss';

.center-image {
    display: flex;
    flex-direction: column;
    position: relative;

    span {
        margin-top: 10px;
    }

    &__no {
        color: $grey_dark;
    }

    &__delete {
        position: absolute;
        background: var(--delete_color);
        padding: 5px;
        top: 24px;
        right: 0;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;

        border-radius: var(--border_radius);

        &:hover {
            opacity: .8;
        }
        
        
        svg {
            display: inline-block;
            width: 20px;
            fill: white;

            margin-right: 5px;

        }
    }
}