@import '../../settings';

.messageFooter {
    display:  flex;
    justify-content: space-between;

    input[type=text] {
        width: 300px;
    }

    & > div:nth-child(1) {
        p + p {
            margin-top: 10px;
        }

        span {
            display: inline-block;
            width: 180px;
        }

        .red,
        .yellow,
        .green {
            display: inline-block;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-left: 10px;
            vertical-align: middle;   
        }
        .green {
            background: $green;
        }
        .yellow {
            background: $yellow;
        }
        .red {
            background: $red;
        }
    }

    & > div:nth-child(2) {
        .hint {
            margin: 10px 0 0 10px;
            display: inline-block;
            font-size: 12px;
        }
    }

    & > div:nth-child(3) {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        min-width: 300px;
    }

    &__save {
        margin: 5px;
        text-align: right;;
    }
}