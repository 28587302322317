// COLORS
$grey_light: rgb(245, 245, 245);
$grey: rgb(204, 204, 204);
$grey_dark: rgb(150, 150, 150);

$green: #3AC13A;
$yellow: #FFE95E;
$red: #DB1C1C;

// defaults, get overWritten by customer specific values (see -> helper)
:root {
  --primary_light: #e3ffe3;
  --primary: #7Aff7A;
  --primary_transparent: #f7fff7;
  --primary_dark: #4Cff4C;

  --secondary_light: #eeeeff;
  --secondary_transparent: #f7f7ff;
  --secondary: #7777FF;
  
  --text_on_primary: black;
  --text_on_secondary: white;
  --font_color: rgb(80,80,80);
  --link_color: rgb(80,80,80);

  --font: "Arial", "Helvetica", sans-serif;
  --font_weight: 400;
  --letter_spacing: 0.02rem;

  --border_radius: 5px;
  --border_radius_wide: 20px; 
  --border_width: 1px;
  --border_style: solid;

  --active: 'lime';
  --inactive: 'hotpink';

  --edit_color: #4B90CB;
  --activate_color: #4BCB6D;
  --deactivate_color: #CB9A4B;
  --delete_color: #CB4B4B;

  --default_lightgrey: #FAFAFA;
  --default_midgrey: #EFEFEF;
  --disabled: #DBDBDB;

  --dropdown_background: rgb(150, 150, 150);
  --text_on_dropdown: #000;
}

:root {
    --primary_light: rgb(153, 203, 195);
    --primary: rgb(0, 168, 165);
    --primary_transparent: #E5F6F6;
    --primary_dark: rgb(0, 131, 117);

    --secondary_light: #FBEEE9;
    --secondary: #D25425;

    --font: "TT Rounds Condensed", sans-serif;
    --font_weight: 400;
    --text_on_primary: white;
    --text_on_secondary: white;
    --link_color: rgb(0, 131, 177);

    --border_radius: 4px;
    --border_width: 1px;
    --border_style: solid;

    --active: rgb(0, 168, 165);
    --inactive: #CCC;

    --toggle_switch: white;
    --toggle_switch_line: white;

    --edit_color: rgb(0, 168, 165);
    --activate_color: #A6A6A6;
    --deactivate_color: #A6A6A6;
    --delete_color: #D25425;

    --dropdown_background: #99CBC3;
    --text_on_dropdown: #000;
  }

// UTILITIES
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
