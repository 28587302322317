.organigram {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 700px;
    
    span {
        line-height: 1.25rem;
    }

    .add-levels__container {
        margin: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background-color: #e6e6e6;
        border-radius: 5px;
        min-height: 70px;

        span {
            font-weight: 600;
        }

        .add-level {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            background-color: #e6e6e6;
            min-height: 60px;

            .level-input {
                display: flex;

                &--isDisabled {
                    opacity: 0.3;
                    pointer-events: none;
                }

                input {
                    height: 30px;
                    width: 175px;
                    border-radius: 5px;
                    border: none;
                }

                input:focus {
                    outline: 1px solid var(--primary);
                }

                svg {
                    fill: var(--primary);
                }

                svg:hover {
                    fill: #ffffff;
                    cursor: pointer;
                    border: 1px solid var(--primary);
                    border-radius: 15px;

                    g {
                        stroke: var(--primary);
                    }
                }
            }
        }
    }

    .level-lists {
        display: flex;
        position: relative;
        background-color: #fafafa;
        border-radius: 5px;

        .level-list {
            width: 100%;

            ul {
                display: flex;
                flex-direction: column;   
            }

            ul > li {
                display: flex;
                justify-content: space-around;
                
                label {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    min-width: 180px;
                    max-width: 180px;
                    background-color: #ffffff;
                    margin-bottom: 5px;
                    border: 1px dotted gray;
                    border-radius: 5px;
                    cursor: default;

                    span {
                        text-indent: 5px;
                    }
                }

                svg {
                    background-color: #fafafa;
                    translate: -3px;

                    circle {
                        fill: var(--secondary);
                    }
                }

                svg:hover {
                    fill: #ffffff;
                    cursor: pointer;
                    border: 1px solid var(--secondary);
                    border-radius: 15px;

                    g {
                        circle {
                            fill: #ffffff;
                        }

                        path {
                            fill: var(--secondary);
                        }
                    }
                }
            }
        }
    }
}
