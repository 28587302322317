.centersList {
    &__noResults {
        margin: 0 auto;
        width: 200px;
       
        padding: 20px;
        text-align: center;
        
        border-radius: var(--border_radius);

        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}