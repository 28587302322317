.centersSorting {
    .col {
        width: 15%;
        cursor: default;
        &--name {
            padding-left: 0px;
            cursor: pointer;
        }

        &--options {
            min-width: 180px;
        }
    }
}