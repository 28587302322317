.centersPagination,
.userPagination {
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    justify-content: center;

    &__page {
        margin: 10px 15px;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        
        &:hover {
            background: white;
            color: var(--primary);
            border-color: var(--primary);
        }

        &--active {
            color: var(--text_on_primary);
            background: var(--primary);
        }

        &--prev {
            svg {
                transform: rotate(90deg);
            }
        }

        &--next {
            svg {
                transform: rotate(-90deg);
            }
        }
    }
}