@import '../../settings.scss';

.centersFilter, 
.userFilter {
    @include clearfix();
    margin-bottom: 20px;
    font-weight: 400;

    &--open {
        .centersFilter__filters,
        .userFilter__filters {
            display: flex;
        }   

        padding: 0 0 20px;
        border-bottom: var(--border_width) solid var(--primary);
    }

    &__toggle {
        user-select: none; 

        float: right;
        cursor: pointer;
        color: var(--primary);
        font-size: 16px;

        &::after {
            content: '+';
            display: inline-block;
            margin-left: 10px;
            font-size: 3em;
            vertical-align: middle;
            transform: rotate(0deg);
            transition: transform .3s;

            .centersFilter--open &, 
            .userFilter--open & {
                transform: rotate(45deg);
            }
        }
    }

    &__clear {
        clear: right;
        float: right;
        cursor: pointer;
        color: var(--secondary);
        font-size: 16px;
    }

    &__filters {
        display: none;

        justify-content: flex-start;

        width: calc(100% - 180px);
        float: left;

        & > div {
            margin-right: 50px;

            &:last-child {
                margin: 0;
            }
        }

        input[type="text"] {
            width: 170px;
        }

        input[type="date"] + span {
            display: inline-block;
            margin: 0 10px;
        }
    }
}