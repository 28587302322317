@import '../../settings.scss';

.center-authentication {
    margin-top: 20px;
    padding: 10px;
    min-width: 320px;
    max-width: 1000px;

    & > div {
        margin: 20px 0;
    }

    &__row {
        padding-bottom: 20px;
        border-bottom: 1px solid #efefef;
    }

    .authentication-form {
        margin-bottom: 30px;

        & > div {
            margin: 10px 0;
        }

        &__row {
            width: 100%;
            max-width: 1000px;
            display: flex;

            .flex {
                position: relative;
                display: flex;
                width: 100%;
                margin-right: 20px;
                flex-direction: column;
                justify-content: flex-start;
                align-items: normal;

                & > h3 {
                    margin-bottom: 20px;
                }

                & > button {
                    min-width: 250px;
                    max-width: 300px;
                    margin: 20px 0 40px 0;
                }

                label {
                    & > input[type='checkbox'] {
                        margin: 10px 10px 10px 0;
                    }

                    & > input[type='text'] {
                        width: 150px;
                        margin: 0 10px 10px 0;
                    }

                    & > input[type='number'] {
                        width: 150px;
                        margin: 0 10px 10px 0;
                    }
                }

                & > div input[type='number'] {
                    position: absolute;
                    margin-left: 80px;
                    width: 100px;
                }

                & > span {
                    padding: 5px;
                    color: $grey_dark;
                    line-height: 1rem;
                    font-size: 12px;
                }
            }

            .success-message {
                position: absolute;
                opacity: 0;
                bottom: 0;
                translate: 0 -10px;
                transition: opacity 0.3s;

                span {
                    color: $grey_dark;
                }

                &--isShown {
                    opacity: 1;
                }
            }
        }
    }

    &__generate {
        margin: 20px 0;
        display: flex;

        button + span {
            display: inline-block;
            padding: 0 10px;
            color: $grey_dark;
            max-width: 50%;
            line-height: 1rem;
            font-size: 12px;
        }
    }

    &__token {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        textarea {
            max-width: 70%;
            width: 70%;
        }
    }

    &__info {
        span {
            font-weight: bold;
        }
    }

    .ip-restriction {
        position: relative;

        label > h2 {
            margin-bottom: 20px;
        }

        .centerForm__row {
            margin-bottom: 20px;
        }

        .success-message {
            position: absolute;
            opacity: 0;
            bottom: 0;
            translate: 0 30px;
            transition: opacity 0.3s;

            span {
                color: $grey_dark;
            }

            &--isShown {
                opacity: 1;
            }
        }

        &__sub-container {
            display: flex;
            flex-direction: column;

            & > * {
                margin-bottom: 10px;
            }
        }

        &__input-container {
            display: flex;
            flex-direction: row;
            height: 75px;
            background-color: #efefef;
            border-radius: var(--border-radius);
            padding: 5px;

            & > span {
                height: 15px;
                position: relative;
                top: 50%;
            }

            & > div {
                & > svg {
                    position: relative;
                    top: 40%;
                    fill: var(--primary);
                    overflow: visible;

                    &:hover {
                        fill: #ffffff;
                        cursor: pointer;
                        border: 1px solid var(--primary);
                        border-radius: 15px;

                        g {
                            stroke: var(--primary);
                        }
                    }
                }
            }
        }

        // TODO: fix precedence
        &--adding-is-disabled.ip-restriction--adding-is-disabled {
            & > svg {
                position: relative;
                top: 40%;
                fill: var(--inactive);
                overflow: visible;

                &:hover {
                    fill: var(--inactive);
                    cursor: not-allowed;
                    border: unset;

                    g {
                        stroke: #fff;
                    }
                }
            }
        }

        &__edit-field {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px;

            span {
                font-weight: 800;
            }

            input {
                border-radius: 5px;
            }
        }

        &__address-entry {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #fafafa;

            & > div {
                & > svg {
                    position: relative;
                    top: 40%;
                    fill: var(--secondary);
                    overflow: visible;

                    &:hover {
                        cursor: pointer;
                        border: 1px solid var(--secondary);
                        border-radius: 15px;

                        g {
                            fill: #fff;
                            circle {
                                stroke: var(--secondary);
                            }
                            path {
                                fill: var(--secondary);
                            }
                        }
                    }
                }
            }
        }

        &__delete-container {
            & > ul > li {
                & > * {
                    margin: 5px 0;
                    padding: 5px 0;
                }

                label {
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border: #ced4da dotted 2px;
                    border-radius: 5px;
                    height: 35px;
                    text-indent: 5px;
                    color: unset;
                    cursor: unset;
                }
            }
        }

        .wide {
            width: 250px;
        }

        .narrow {
            width: 100px;
        }
    }
}
