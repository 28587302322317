.header {
    padding: 10px 20px;
    border-bottom: 5px solid var(--primary);
    
    min-height: 50px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    .wrapper {
        display: flex;
        align-items: flex-end;
    }
    
    .version {
        position: relative;
        top: 3px;
        font-size: 12px;
    }
    
    .logo {
        position: relative;
        height: 50px;
        margin-right: 30px;
        color: var(--primary);
    
        img {
            width: auto;
            height: 100%;
            margin-right: 20px;
        }
    
        span {
            font-weight: 700;
            font-size: 20px;
        }
    }
}