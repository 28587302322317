textarea,
input[type="text"],
input[type="tel"],
input[type="date"],
input[type="phone"],
input[type="password"],
input[type="number"],
input[type="email"] {
    display: inline-block;
    margin: 0;
    
    padding: 6px 15px;
    border: var(--border_width) var(--border_style) $grey;
    border-radius: var(--border_radius_wide);

    height: 38px;
    line-height: 16px;
    text-align: start;
    font-family: var(--font);
    font-weight: 400;
    cursor: text;

    background-color: white;
    background-clip: padding-box;
    color: var(--font_color);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: none;

    &:focus {
        background-color: white;
        color: var(--font_color);
        border-color: var(--primary);
        outline: 0;
        box-shadow: 0 0 0 3px var(--primary_light);
    }

    &:disabled {
        background: $grey_light;
        border: none;
    }
}

input[type="radio"],
input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
}

textarea {
    width: 300px;
    height: 80px;
    padding: 6px;
    border-radius: var(--border_radius);
}

label {
    cursor: pointer;
}

.error-message {
    display: none;

    .has-error & {
        display: block;
        color: $red;
        margin-top: 10px;
    }
}

.has-error {
    label, input, textarea {
        color: $red;
    }
    input, textarea {
        border-color: $red;
    }
    .DraftEditor-root {
        color: $red;
    }
    .RichTextEditor__root___2QXK- {
        border-color: $red;
    }
}