.dropdown {
    position: relative;
    text-align: left;
    font-weight: 700;
  
    &__label {
      background: var(--dropdown_background);
      padding: 12px;
      border-radius: var(--border_radius);
      color: var(--text_on_dropdown);
      
      position: relative;
  
      cursor: pointer;
      line-height: 1.5;

      span {
        display: inline-block;
        margin-right: 60px;
      }
      
      .dropdown__toggleIcon {
        fill: var(--text_on_dropdown);
        width: 28px;
        height: 28px;
  
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
      }

      .dropdown--open & {
        border-radius: var(--border_radius) var(--border_radius) 0 0;
      }
  
      .dropdown--open.dropdown--top & {
        border-radius: 0 0 var(--border_radius) var(--border_radius);
      }
    }
  
    &__content {
      position: absolute;
      overflow: hidden;
      background: white;
      z-index: 99;
  
      height: 0;
      width: 100%;
  
      .dropdown--open & {
        height: auto;
        overflow: auto;
        border: 1px solid var(--dropdown_background);
      }

      .dropdown--scroll & {
        height: 225px;
        overflow-y: scroll;
      }
  
      .dropdown--top & {
        bottom: 100%;
      }
  
      & > div {
        display: block;
  
        background: white;
        
        & > div {
          padding: 15px;
          border-bottom: 1px solid var(--dropdown_background);
          cursor: pointer;
      
          &:last-child {
            border: none;
          }
      
          &:hover {
            background: var(--dropdown_background);
          }
        }
      }
    }

    &--flags {
      .dropdown__content {
        text-align: center;
      }
  
      .dropdown__label {
        background: #fff;
        padding: 9px 10px;
  
        span {
          display: flex;
          margin-right: 30px;
        }
      }
  
      svg {
        width: 30px;
        height: 20px;
        border-radius: var(--border_radius);
  
      }
  
      .dropdown__toggleIcon {
        fill: darkgrey;
        width: 20px;
        height: 20px;
      }
  
     .dropdown__content {
      border-color: var(--dropdown_background);
  
       & > div {
        & > div {
          padding: 8px;
          line-height: 1;
          border-color: var(--dropdown_background);
  
          &:hover {
            background: var(--dropdown_background);
          }
        }
       }
     }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.3;
      background: #dbdbdb;
    }
  }