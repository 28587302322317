@import '../../settings.scss';

.centerChatBlocks {
    padding: 10px 0;

    h2 {
        font-size: 21px;
    }

    h3 {
        padding-bottom: 15px;
        border-bottom: 1px solid var(--default_midgrey);
    }    

    p {
        margin: 20px 0;
        line-height: 20px;
        max-width: 500px;
    }

    &__toggle {
        user-select: none; 

        float: right;
        cursor: pointer;
        color: var(--primary);
        font-size: 16px;
        position: relative;
        top: -16px;

        &::after {
            content: '+';
            display: inline-block;
            margin-left: 10px;
            font-size: 3em;
            font-weight: 500;
            vertical-align: middle;
            transform: rotate(0deg);
            transition: transform .3s;

            .centerChatBlocks--open & {
                transform: rotate(45deg);
            }
        }
    }

    &__list {
        margin: 20px 0;
    }
}