@import '../../settings.scss';

.upload-form {
    width: 300px;
    margin-right: 20px;

    background: #fafafa;
    padding: 10px;

    input {
        display: block;
        max-width: 250px;
    }

    img {
        width: 100%;
        height: auto;
    }

    &__form {
        margin-top: 20px;
    }

    &__hint {
        color: $grey_dark;
    }

    &__button-wrapper {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    &__error {
        margin-left: 20px;
        color: $red;
    }

}