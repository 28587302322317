.userSorting {
    position: relative;
    .col {
        &.col--ipRestriction {
            cursor: default;
        }

        &.col--options {
            position: absolute;
            right: 0;
            display: flex;
            justify-content: flex-end;
        }
    }
}
