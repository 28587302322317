@import '../../settings.scss';

.centerForm {
    margin-top: 20px;
    padding: 10px;
    min-width: 320px;

    &>div {
        margin: 20px 0;

        &>button {
            margin: 20px 0;
        }
    }

    &>div+div {
        border-top: 1px solid #efefef;
    }


    h3 {
        margin-bottom: 10px;
    }

    input {
        width: 300px;
        margin-right: 10px;
    }

    &__row {
        width: 100%;
        max-width: 700px;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;

        &>textarea,
        &>input[type="text"] {
            margin-right: 20px;
        }

        &>span {
            padding: 5px;
            color: $grey_dark;
            max-width: 50%;
            line-height: 1rem;
            font-size: 12px;
        }
    }

    .hint {
        display: block;
        color: var(--font_color);
    }

    .language-select {
        position: relative;
        margin: 15px 0;
        max-width: 115px;
        height: 38px;

        .languageSelect {
            padding: 0;
            height: 40px;
        }

        span {
            color: var(--font_color);
            line-height: 1rem;
            font-size: 12px;
        }

        .dropdown__label {
            background: var(--dropdown_background);
        }
        .dropdown__toggleIcon {
            fill: var(--font_color);
        }
    }
}