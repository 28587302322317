@import "../../settings";

.messageBlock {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.messageBlock__container {
    display: flex;
    justify-content: space-between;

    & > div:nth-child(1) {
        span {
            &::before {
                content: "";

                width: 20px;
                height: 20px;

                margin-right: 10px;
                border-radius: 50%;

                display: inline-block;
                vertical-align: middle;

                background: $red;
            }

            &.is-active {
                &::before {
                    background: $green;
                }
            }
        }
    }

    & > div:nth-child(2) {
        max-width: 60%;
        div + div {
            margin-top: 20px;
        }

        input,
        textarea {
            width: 350px;
        }

        textarea {
            height: 100px;
        }

        .messageBlock__hint {
            display: inline-block;
            margin-left: 10px;
            padding-top: 11px;
            vertical-align: top;
            max-width: 40%;

            span {
                display: block;
                font-size: 12px;
            }

            span + span {
                margin-top: 5px;
                font-size: 12px;
                color: $grey_dark;
            }
        }
    }

    & > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .btn:last-child {
            margin: 0 0 0 40px;
        }

        p + p {
            margin-top: 20px;
        }
    }

    &__save {
        margin: 5px;
        text-align: right;
    }
}
