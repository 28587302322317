.headline {
    display: flex;
    justify-content: space-between;
    
    padding: 10px;
    border-bottom: 1px solid var(--primary);

    & + .headline {
        margin-top: 20px;
    }

    h2 {
        font-size: 36px; 
        
        span {
            font-size: 24px;
            display: inline-block; 
            margin-left: 20px;
        }
    }

    &--small {
        h2 {
            font-size: 24px;

            span {
                font-size: 20px;
            }
        }
    }
    .btn svg {
        vertical-align: top;
    }
}