@use 'sass:math';

// FONT IMPORT
@font-face {
    font-family: 'TT Rounds Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/TTRoundsCondensed-Regular.otf');
}

@font-face {
    font-family: 'TT Rounds Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/TTRoundsCondensed-Bold.otf');
}

// RESET
@import 'reset';

// SETTINGS
@import 'settings';
@import 'buttons';
@import 'forms';
@import 'alert';
@import 'rte';

* {
    box-sizing: border-box;
}

body {
    color: var(--font_color);
    font-family: var(--font);
    font-weight: var(--font_weight);
    font-size: 14px;
}

input:focus {
    box-shadow: none !important;
}

textarea:focus {
    outline: none !important;
    border-color: var(--primary);
    box-shadow: none !important;
}

.container {
    max-width: 1440px;
    margin: 40px auto;

    &--small {
        max-width: 600px;
    }
}

a,
a:visited {
    color: var(--text_color);
    text-decoration: none;
}

.col {
    margin: 0 5px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    flex: 0 0 auto;
    width: math.percentage(math.div(120, 1440));

    &--name,
    &--options,
    &--email {
        width: math.percentage(math.div(200, 1440));
    }
}

h2 {
    font-size: 21px;
    font-weight: 700;
}

h3 {
    margin: 15px 0;
    font-size: 16px;
    font-weight: 700;
}

p {
    margin: 20px 0;
    line-height: 20px;
}

sup {
    font-size: 75%;
    vertical-align: super;
}

.flex {
    display: flex;
    justify-content: space-between;
}

*::placeholder {
    color: $grey_dark;
}
