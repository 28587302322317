@import '../../settings.scss';

.edit-session-report {
    margin-top: 20px;
    padding: 10px;
    min-width: 320px;
    max-width: 700px;

    &__toggleWrapper {
        max-width: 420px;
        margin: 5px 0;
        padding: 5px 0;
    }

    .centerFeatures__toggleWrapper+.centerFeatures__toggleWrapper {
        border-top: 1px solid $grey;
    }
}