@import '../../settings.scss';

.centersListEntry,
.userListEntry {
    display: flex;
    padding: 15px 10px;
    margin: 10px 0;
    font-weight: 500;
    justify-content: flex-start;
    align-items: center;
    
    &:nth-child(2n) {
        background: var(--default_lightgrey);
    }

    &:nth-child(2n - 1) {
        background: var(--default_midgrey);
    }

    button {
        width: 40px;
    }

    .col {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        &--name--green, &--name--red {
            &::before {
                margin-right: 8px;
                content: '';
                width: 10px;
                height: 10px;
                display: inline-block;
                border-radius: 50%;
            }
        }

        &--name--green {
            &::before {
                background: #4BCB6D;
            }
        }
        &--name--red {
            &::before {
                background: #CB4B4B;
            }
        }

        &--options {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
    
            button {
                position: relative;
                font-size: 1em;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 5px;
                color: white;
                border: 2px solid transparent;
                border-radius: var(--border_radius_wide);
        
                &:first-child {
                    background: var(--edit_color);
                    border-color: var(--edit_color);

                    &:hover {
                        color: var(--edit_color);

                        svg {
                            fill: var(--edit_color);
                        }
                    }                    
                }
                &.activate {
                    background: var(--activate_color);
                    border-color: var(--activate_color);

                    &:hover {
                        color: var(--activate_color);

                        svg {
                            fill: var(--activate_color);
                        }
                    }                    
                }
                &.deactivate {
                    background: var(--deactivate_color);
                    border-color: var(--deactivate_color);

                    &:hover {
                        color: var(--deactivate_color);

                        svg {
                            fill: var(--deactivate_color);
                        }
                    }
                }
                &:last-child {
                    background: var(--delete_color);
                    border-color: var(--delete_color);
                    margin-right: 0;

                    &:hover {
                        color: var(--delete_color);

                        svg {
                            fill: var(--delete_color);
                        }
                    }
                }

                &:hover {
                    //opacity: .8;
                    background: white;
                }
        
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin-right: 0;
                    fill: white;
                }
                span {
                    vertical-align: middle;
                }
            }
        }

        &--disclaimer {
            position: relative;
            svg {
                position: absolute;
                top: 50%;
                translate: 100% -50%;
            }
        }
    }
}