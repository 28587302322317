@import '../../settings.scss';

.userForm {
    margin-top: 20px;
    padding: 10px;
    font-weight: 400;

    & > div {
        margin: 20px 0;
    }
    h3 {
        margin-bottom: 10px;
    }

    input {
        width: 300px;
    }

    button {
        margin-top: 20px;
    }
  
    .passwordAssignmentConfig {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 900px;

        & > div:nth-child(2) {
            margin-top: 20px;
            label {
                display: flex;
                align-items: center;

                input[type="checkbox"] {
                    width: 20px;
                }
            }
        }
    }

    .passwordConfig {
        width: 100%;
        max-width: 900px;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
    }

    .hint {
        display: inline-block;
        margin-left: 20px;
        line-height: 1rem;
        font-size: 12px;
    }

    .dispatchConfig {
        width: 100%;
        max-width: 900px;
        justify-content: space-between;
        display: flex;

        button {
            display: block;
            margin: 20px 0;
        }
    }

    .dropdown {
        width: 300px;
    }

    &__row {
        width: 100%;
        max-width: 700px;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;

        & > textarea {
            margin-right: 20px;
        }

        & > span {
            padding: 5px;
            color: $grey_dark;
            max-width: 50%;
            line-height: 1rem;
            font-size: 12px;

            .hint {
                display: block;
                margin: 0;
                color: var(--font_color);
            }
        }
    }

    &--api {
        .passwordConfig,
        .dispatchConfig,
        .roleSelect {
            display: none;
        }
    }

    .organigramSelect {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
