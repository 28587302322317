.tab {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    background-color: #e6e6e6;
    border: none;
    margin-right: 3px;

    padding: 5px 20px;
}