@import '../../settings.scss';

.userSorting,
.centersSorting {
    display: flex;
    padding: 10px;
    margin: 10px 0;
    font-weight: 700;

    justify-content: flex-start;
    align-items: center;

    user-select: none;

    .col {
        @include clearfix();

        cursor: pointer;
        
        &--name {
            padding-left: 20px;
        }
      
        svg {
            display: inline-block;
            width: 20px;
            vertical-align: middle;
            fill: $grey;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;

            max-width: calc(100% - 30px);
        }
    }

    &--name {
        .col.col--name {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC,
        &.centersSorting--DESC {
            .col.col--name {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.centersSorting--ASC,
        &.userSorting--ASC {
            .col.col--name {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--email {
        .col.col--email {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--email {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--email {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--dispatchCenter {
        .col.col--dispatchCenter {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--dispatchCenter {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--dispatchCenter {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--role {
        .col.col--role {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--role {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--role {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--ipRestriction {
        .col.col--ipRestriction {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--ipRestriction {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--ipRestriction {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--level1 {
        .col.col--level1 {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--level1 {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--level1 {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--level2 {
        .col.col--level2 {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--level2 {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--level2 {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--level3 {
        .col.col--level3 {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--level3 {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--level3 {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--disclaimerAccpeted {
        .col.col--disclaimer {
            svg {
                fill: var(--primary);
            }
        }
        &.userSorting--DESC {
            .col.col--disclaimer {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &.userSorting--ASC {
            .col.col--disclaimer {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}