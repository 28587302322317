.languageSelect {
    padding: 0 20px;

    .dropdown {
        .dropdown--flags, .dropdown__content {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .dropdown__label {
        .languageSelect__language {
            display: none;
        }
    }

    .dropdown__content {
        width: 100%;
        overflow: auto;
    }
    &__option {
        display: flex;
        border-bottom: var(--dropdown_background) solid 2px;

        :nth-child(1) {
            span {
                display: none;
            }
        }

        :nth-child(2) {
            svg {
                fill: var(--primary);
            }
        }

        * {
            white-space: nowrap;
        }

        svg {
            display: inline-block;
        }
    }
}
