.centerImageFormat {
    width: 300px;
    margin-right: 20px;
    padding: 5px;
    background-color: #FAFAFA;

    & > div {
        padding: 5px;
        label {
            input {
                width: auto;
            }
        }
    }
}