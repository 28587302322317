@import '../../settings.scss';

.centerChatBlock {
    padding: 10px;
    margin: 4px 0;
    border: 1px dashed $grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border_radius);

    cursor: move;

    &__delete {
        cursor: pointer;
        display: inline-block;
        padding: 6px 12px; 
        background: var(--delete_color);
        border: 1px solid var(--delete_color);
        border-radius: var(--border_radius_wide);
        color: var(--text_on_secondary);
        font-weight: 700;

        transition: color .3s, background-color .3s;

        &:hover {
            background: white;
            border: 1px solid var(--delete_color);
            color: var(--delete_color);
        }
    }
}