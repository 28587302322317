button, .btn {
  display: inline-block;
  font-weight: 700;
  color: var(--font_color);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: var(--border_width) var(--border_style) black;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: var(--border_radius_wide);
  font-family: var(--font);
  letter-spacing: var(--letter_spacing);

  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;

  svg {
    width: 1.5em;
    vertical-align: middle;
    margin-right: 5px;
  }

  &:hover {
    color: var(--text_on_dropdown);
    background: var(--dropdown_background);
    border-color: var(--dropdown_background);
    opacity: 1;
  }

  &.btn--active {
    color: var(--text_on_dropdown);
    background: var(--dropdown_background);
    border-color: var(--dropdown_background);
  }

  &.btn--primary {
    color: var(--text_on_primary);
    background-color: var(--primary);
    border: var(--border_width) var(--border_style) var(--primary);

    svg {
      transition: fill .15s ease-in-out;
      fill: var(--text_on_primary);
    }

    &:hover {
      background-color: white;
      color: var(--primary);
      opacity: 1;

      svg {
        fill: var(--primary);
      }
    }

    &.btn--active {
      background: var(--primary_dark);
      border-color: var(--primary_dark);

      &:hover {
        background-color: white;
        color: var(--primary_dark);
        opacity: 1;

      svg {
        fill: var(--primary);
      }
      }
    }
  
  }

  &.btn--secondary {
    color: var(--text_on_secondary);
    background-color: var(--secondary);
    border: var(--border_width) var(--border_style) var(--secondary);

    svg {
      transition: fill .15s ease-in-out;
      fill: var(--text_on_secondary);
    }

    &:hover {
      background-color: white;
      color: var(--font_color);
      opacity: 1;

      svg {
        fill: var(--secondary);
      }
    }
  }

  &.btn--active {
    background: var(--primary_light);
    border-color: var(--primary_light);
  }
  
  &.btn--full {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:disabled:hover {
    background: var(--disabled);
    border: var(--disabled);
    color: $grey_dark;
    border: none;
    cursor: not-allowed;
  }
}
