.centersListEntry {
    .col {
        width: 15%; 

        &--options {
            min-width: 180px;
            width: 20%;
            justify-content: flex-end;
        }

        &__buttons {
            width: 40px;
        }
    }
}